<template>
  <div
    style="height: inherit"
  >
    <b-row
      v-if="isLoading || !stage"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-row v-else>
      <b-col>
        <b-card
          no-body
          class="p-1"
        >
          <b-row class="align-items-center justify-content-between">
            <b-col
              sm="12"
              md="6"
            >
              <h5 class="m-0">
                {{ stage?.name }}
              </h5>
            </b-col>
          </b-row>
        </b-card>

        <tournament-stage-single-elimination v-if="stage?.type?.id === StageType.SINGLE_ELIMINATION" />
        <tournament-stage-double-elimination v-if="stage?.type?.id === StageType.DOUBLE_ELIMINATION" />
      </b-col>
    </b-row>
  </div>
</template>
<script>

import TournamentStageSingleElimination from '@/views/tournament/TournamentStageSingleElimination.vue'
import TournamentStageDoubleElimination from '@/views/tournament/TournamentStageDoubleElimination.vue'
import StageType from '@/constants/StageType'
import {
  BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BCard,
    BCol,
    BRow,
    TournamentStageDoubleElimination,
    TournamentStageSingleElimination,
  },
  data() {
    return {
      StageType,
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
    stage() {
      const stages = this.$store.getters['tournament/getTournament']?.stages || []

      return stages.find(stage => stage.id === Number(this.$route.params.stageId))
    },
  },
  watch: {

  },
  async mounted() {
    await this.$store.dispatch('tournament/fetchTournament', Number(this.$route.params.id))
  },
  methods: {

  },
}
</script>
