<template>
  <div
    style="height: calc(100vh - 275px); overflow: auto;"
  >
    <b-row
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-row class="">
      <b-col>
        <h4>WB</h4>
        <bracket
          v-if="brackets?.length > 0"
          :add-grand-finale-round="true"
          :flat-tree="brackets[0].nodes"
          :show-edit-results="true"
          @onMatchResultClick="handleMatchResultClick"
        >
          <template #player="{ player }">
            {{ player.name }}
          </template>
        </bracket>
      </b-col>
    </b-row>

    <b-row class="mt-1 h-100">
      <b-col>
        <h4>LB</h4>
        <bracket
          v-if="brackets?.length > 0"
          :key="2"
          :flat-tree="brackets[1].nodes"
          :show-edit-results="true"
          @onMatchResultClick="handleMatchResultClick"
        >
          <template #player="{ player }">
            {{ player.name }}
          </template>
        </bracket>
      </b-col>
    </b-row>

    <match-info-modal :match-id="selectedMatchId" />
  </div>
</template>
<script>
import {
  BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import Bracket from '@/components/bracket/Bracket.vue'
import MatchInfoModal from '@/views/tournament/MatchInfoModal.vue'

export default {
  components: {
    MatchInfoModal,
    Bracket,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      participants: [],
      brackets: null,
      searchQuery: '',
      canEditResults: false,
      selectedMatchId: null,
      perfectScrollbarSettings: {
        // maxScrollbarLength: 150,
      },
    }
  },
  computed: {
    showTeamWaitingApprovalCard() {
      return false
    },
    showJoinTournamentCard() {
      return true
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
  },
  async created() {
    // await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    // eslint-disable-next-line no-use-before-define
    this.brackets = brackets[0].groups
  },
  methods: {
    handleMatchResultClick(matchId) {
      this.selectedMatchId = matchId

      this.$bvModal.show('match-info-modal')
    },
  },
}

const brackets = [
  {
    groups: [
      {
        id: 1,
        nodes: [
          {
            id: 1,
            next: 5,
            matchId: 1,
            roundId: 1,
            player1: {
              id: '8',
              name: 'CTje',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '9',
              name: 'Xendaria Esports',
              score: 1,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 2,
            next: 6,
            matchId: 2,
            roundId: 1,
            player1: {
              id: '5',
              name: 'Smrkci',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '12',
              name: 'Fagoti',
              score: 1,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 3,
            next: 7,
            matchId: 3,
            roundId: 1,
            player1: {
              id: '7',
              name: 'Diamant Esports',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '10',
              name: 'Zero Comms',
              score: 0,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 4,
            next: 8,
            matchId: 4,
            roundId: 1,
            player1: {
              id: '6',
              name: '100Tatov',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '11',
              name: 'ZipTies',
              score: 0,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 5,
            next: 9,
            matchId: 5,
            roundId: 2,
            player1: {
              id: '4',
              name: 'KrizKrazKrompircki',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '8',
              name: 'CTje',
              score: 5,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 6,
            next: 9,
            matchId: 6,
            roundId: 2,
            player1: {
              id: '1',
              name: 'ISKRA',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '5',
              name: 'Smrkci',
              score: 3,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 7,
            next: 10,
            matchId: 7,
            roundId: 2,
            player1: {
              id: '2',
              name: 'Osli',
              score: 17,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '7',
              name: 'Diamant Esports',
              score: 19,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 8,
            next: 10,
            matchId: 8,
            roundId: 2,
            player1: {
              id: '3',
              name: 'Team Overflow',
              score: 2,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '6',
              name: '100Tatov',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 9,
            next: 11,
            matchId: 9,
            roundId: 3,
            player1: {
              id: '4',
              name: 'KrizKrazKrompircki',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '1',
              name: 'ISKRA',
              score: 2,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 10,
            next: 11,
            matchId: 10,
            roundId: 3,
            player1: {
              id: '7',
              name: 'Diamant Esports',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '6',
              name: '100Tatov',
              score: 5,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 11,
            next: 12,
            matchId: 11,
            roundId: 4,
            player1: {
              id: '4',
              name: 'KrizKrazKrompircki',
              score: 2,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '7',
              name: 'Diamant Esports',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 12,
            next: null,
            matchId: 12,
            roundId: 5,
            player1: {
              id: '7',
              name: 'Diamant Esports',
              score: 1,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '2',
              name: 'Osli',
              score: 0,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
        ],
        __typename: 'BracketGroup',
      },
      {
        id: 2,
        nodes: [
          {
            id: 1,
            next: 5,
            matchId: 13,
            roundId: 1,
            player1: {
              id: '9',
              name: 'Xendaria Esports',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '3',
              name: 'Team Overflow',
              score: 6,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 2,
            next: 5,
            matchId: 14,
            roundId: 1,
            player1: {
              id: '12',
              name: 'Fagoti',
              score: 0,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '2',
              name: 'Osli',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 3,
            next: 6,
            matchId: 15,
            roundId: 1,
            player1: {
              id: '10',
              name: 'Zero Comms',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '5',
              name: 'Smrkci',
              score: 3,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 4,
            next: 6,
            matchId: 16,
            roundId: 1,
            player1: {
              id: '11',
              name: 'ZipTies',
              score: 2,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '8',
              name: 'CTje',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 5,
            next: 7,
            matchId: 17,
            roundId: 2,
            player1: {
              id: '9',
              name: 'Xendaria Esports',
              score: 1,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '2',
              name: 'Osli',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 6,
            next: 8,
            matchId: 18,
            roundId: 2,
            player1: {
              id: '10',
              name: 'Zero Comms',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '8',
              name: 'CTje',
              score: 11,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 7,
            next: 9,
            matchId: 19,
            roundId: 3,
            player1: {
              id: '1',
              name: 'ISKRA',
              score: 2,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '2',
              name: 'Osli',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 8,
            next: 9,
            matchId: 20,
            roundId: 3,
            player1: {
              id: '6',
              name: '100Tatov',
              score: 9,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '10',
              name: 'Zero Comms',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 9,
            next: 10,
            matchId: 21,
            roundId: 4,
            player1: {
              id: '2',
              name: 'Osli',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            player2: {
              id: '10',
              name: 'Zero Comms',
              score: 5,
              result: 'L',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
          {
            id: 10,
            next: null,
            matchId: 22,
            roundId: 5,
            player1: {
              id: '4',
              name: 'KrizKrazKrompircki',
              score: 7,
              result: 'L',
              __typename: 'Player',
            },
            player2: {
              id: '2',
              name: 'Osli',
              score: 13,
              result: 'W',
              __typename: 'Player',
            },
            __typename: 'BracketNode',
          },
        ],
        __typename: 'BracketGroup',
      },
    ],
    __typename: 'Bracket',
  },
]
</script>

<style lang="scss">
.bracket-scroll-area {
  padding: 0 2rem;
  position: relative;
  height: 100%;
}

.vtb-item {
  display: flex;
  flex-direction: row-reverse;
}

.vtb-item p {
  padding: 20px;
  margin: 0;
  background-color: #999999;
}

.vtb-item-parent {
  position: relative;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.vtb-item-players {
  flex-direction: column;
  margin: 0;
  color: white;
}

.vtb-item-players .vtb-player {
  margin: 2px;
  border: 1px solid #404656;
  border-radius: 5px;
  padding: 10px;
  background-color: #283046;
  min-width: 200px;
  min-height: 42px;
}

.vtb-item-players .winner {
  background-color: #283046;
}

.vtb-item-players .defeated {
  background-color: #283046;
}

.vtb-item-players .winner.highlight {
  background-color: #283046;
}

.vtb-item-players .defeated.highlight {
  background-color: #283046;
}

.vtb-item-parent:after {
  position: absolute;
  content: '';
  width: 25px;
  height: 2px;
  left: 0;
  top: 50%;
  background-color: gray;
  transform: translateX(-100%);
}

.vtb-item-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vtb-item-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;
}

.vtb-item-child:before {
  content: '';
  position: absolute;
  background-color: gray;
  right: 0;
  top: 50%;
  transform: translateX(100%);
  width: 25px;
  height: 2px;
}

.vtb-item-child:after {
  content: '';
  position: absolute;
  background-color: gray;
  right: -25px;
  height: calc(50% + 22px);
  width: 2px;
  top: 50%;
}

.vtb-item-child:last-child:after {
  transform: translateY(-100%);
}

.vtb-item-child:only-child:after {
  display: none;
}

// scrollbars

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #7b7e89;
}

::-webkit-scrollbar-corner {
  /* background-color: transparent; */
}

</style>
