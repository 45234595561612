var render = function () {
  var _vm$stage, _vm$stage2, _vm$stage2$type, _vm$stage3, _vm$stage3$type;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_vm.isLoading || !_vm.stage ? _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s((_vm$stage = _vm.stage) === null || _vm$stage === void 0 ? void 0 : _vm$stage.name) + " ")])])], 1)], 1), ((_vm$stage2 = _vm.stage) === null || _vm$stage2 === void 0 ? void 0 : (_vm$stage2$type = _vm$stage2.type) === null || _vm$stage2$type === void 0 ? void 0 : _vm$stage2$type.id) === _vm.StageType.SINGLE_ELIMINATION ? _c('tournament-stage-single-elimination') : _vm._e(), ((_vm$stage3 = _vm.stage) === null || _vm$stage3 === void 0 ? void 0 : (_vm$stage3$type = _vm$stage3.type) === null || _vm$stage3$type === void 0 ? void 0 : _vm$stage3$type.id) === _vm.StageType.DOUBLE_ELIMINATION ? _c('tournament-stage-double-elimination') : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }