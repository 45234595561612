var render = function () {
  var _vm$brackets, _vm$brackets2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "calc(100vh - 275px)",
      "overflow": "auto"
    }
  }, [_vm.isLoading ? _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _c('b-row', {}, [_c('b-col', [_c('h4', [_vm._v("WB")]), ((_vm$brackets = _vm.brackets) === null || _vm$brackets === void 0 ? void 0 : _vm$brackets.length) > 0 ? _c('bracket', {
    attrs: {
      "add-grand-finale-round": true,
      "flat-tree": _vm.brackets[0].nodes,
      "show-edit-results": true
    },
    on: {
      "onMatchResultClick": _vm.handleMatchResultClick
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref) {
        var player = _ref.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }], null, false, 2469036928)
  }) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "mt-1 h-100"
  }, [_c('b-col', [_c('h4', [_vm._v("LB")]), ((_vm$brackets2 = _vm.brackets) === null || _vm$brackets2 === void 0 ? void 0 : _vm$brackets2.length) > 0 ? _c('bracket', {
    key: 2,
    attrs: {
      "flat-tree": _vm.brackets[1].nodes,
      "show-edit-results": true
    },
    on: {
      "onMatchResultClick": _vm.handleMatchResultClick
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref2) {
        var player = _ref2.player;
        return [_vm._v(" " + _vm._s(player.name) + " ")];
      }
    }], null, false, 2469036928)
  }) : _vm._e()], 1)], 1), _c('match-info-modal', {
    attrs: {
      "match-id": _vm.selectedMatchId
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }